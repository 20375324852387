import React from "react";
import homeBanner from "../../assets/vehicle/metrodecker/metrodecker-home-banner.jpg";
import logoMetrodecker from "../../assets/vehicle/metrodecker/metrodecker-logo-white.png";
// import video from "../../assets/video/FIRST_YORK _B-ROLL.mp4"
import homeMobileBanner from "../../assets/vehicle/metrodecker/metrodecker-banner-mobile1.jpg";
import subBanner from "../../assets/vehicle/metrodecker/sub-banner1.jpg";
import metrodeckerLightest from "../../assets/vehicle/metrodecker/metrodecker-lightest-bus.jpg";
import largeImage from "../../assets/vehicle/metrodecker/metrodecker-3rd-fold.jpg";
import EnquirForm from "../otherComponents/EnquirForm";
import { Helmet } from "react-helmet";
// import SideButton from "../otherComponents/SideButton";

const Metrodecker = ({ setShowMyModal }) => {
  return (
    <>
      <Helmet>
        <title>
          METRODECKER e Bus: Redefining Urban Transportation with Eco-Friendly
          Mobility.
        </title>
        <meta
          name="title"
          content="METRODECKER e Bus: Redefining Urban Transportation with Eco-Friendly Mobility."
        />
        <meta
          name="description"
          content="Discover the future of urban transportation with the METRODECKER e Bus. As a pioneer in eco-friendly mobility solutions, the METRODECKER e Bus offers sustainable and efficient public transit options for modern cities. With its innovative design, advanced technology, and zero-emission performance, the METRODECKER e Bus is redefining the way we move people in urban environments. Experience unparalleled comfort, safety, and reliability aboard the METRODECKER e Bus as we pave the way towards a cleaner and greener future for public transportation."
        />
        <meta
          name="keywords"
          content="METRODECKER e Bus, Urban Transportation, Eco-Friendly Mobility, Public Transit, Sustainable Transportation, 
Zero-Emission Bus, Electric Bus, Future of Transportation, Urban Mobility Solutions, Advanced Technology, Clean Energy, Comfortable Transit, Safe Transportation, Greener Future, "
        />
      </Helmet>
      <section>
        <div
          className="hidden h-[95vh] w-full bg-cover bg-center bg-no-repeat contrast-125 saturate-200 md:block"
          style={{ backgroundImage: `url(${homeBanner})` }}
        ></div>
        <div
          className="block h-[92vh] w-full bg-cover bg-center bg-no-repeat md:hidden  md:contrast-125  "
          style={{ backgroundImage: `url(${homeMobileBanner})` }}
        ></div>
        <div className="xs:bottom-2 absolute bottom-1 h-[30%] w-full bg-gradient-to-t from-black sm:bottom-2  md:-bottom-9 md:h-[20%]"></div>
        <div
          id="mouse"
          className="absolute hidden -ml-4 bottom-10 left-1/2 md:block "
        >
          <div className="flex items-end justify-center w-8 border-2 rounded-full h-14 ">
            <div className="w-1 h-4 mb-1 bg-white border-2 rounded-full animate-bounce "></div>
          </div>
          <div className="flex justify-center mt-1 animate-bounce ">
            <div className="w-4 h-4 rotate-45 border-b-2 border-r-2"></div>
          </div>
        </div>
        <img
          src={logoMetrodecker}
          alt=""
          srcSet=""
          className="absolute h-8 bottom-10 left-4 brightness-200 contrast-200 sm:h-10 md:h-12 lg:left-6 lg:h-14 xl:left-10 xl:h-16 "
        />
      </section>

      <EnquirForm modelValue="Switch Metrodecker" />
      <section className="flex overflow-hidden">
        <div className="flex  h-[60vh] lg:w-3/6  2xl:h-screen ">
          <div className="bg-gradient-to-b from-[#16b895] to-white lg:w-1/3"></div>
          <div
            className="hidden w-full lg:block lg:w-2/3 "
            style={{
              backgroundImage: `url(${subBanner})`,
              // height: '600px',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
            data-aos="fade-right"
            data-aos-duration="1000"
          ></div>
        </div>
        <div
          className="relative h-[60vh] w-full  contrast-125 lg:w-3/6 2xl:h-screen"
          style={{
            backgroundImage: `url(${largeImage})`,
            // height: '600px',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            width: "100%",
          }}
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div className="absolute bottom-0 h-[30%] w-full bg-gradient-to-t from-black  md:-bottom-9 md:h-[50%]"></div>
          <div className="absolute text-4xl text-white bg-opacity-25 bottom-6 left-2 bg-slate-600 lg:text-5xl ">
            Class-leading double-decker
          </div>
        </div>
      </section>
      <section className="flex flex-col-reverse overflow-hidden md:flex-row">
        {/* <span>The bus you've been waiting for.</span> */}
        <div className="flex w-full flex-col justify-center  md:w-1/2 md:p-14 lg:p-20 xl:p-28 2xl:h-[70vh] ">
          <div className="p-4 mb-6 text-4xl lg:font-semibold xl:text-5xl">
            The lightest bus in its class
          </div>
          <img
            src={metrodeckerLightest}
            alt="Metrodecker Lightest"
            srcSet=""
            className="pb-4 md:hidden "
            data-aos="fade-left"
            data-aos-duration="1000"
          />
          <div className="px-4 text-justify font-roboto 2xl:text-xl">
            This award-winning, British-built electric bus has been designed to
            be the lightest in its class, with the highest passenger capacity
            and the latest battery technology.
            <br />
            <br />
            SWITCH metrodecker has been built with passengers, drivers,
            operators, and local authorities in mind, helping them to keep their
            city moving efficiently, whilst reducing its carbon footprint.
            <br />
            <br />
          </div>
          <a href="#enquire">
            <button className="m-4 w-min whitespace-nowrap bg-[#515ef5] px-4 py-2 text-left text-white hover:bg-[#20c997] ">
              ENQUIRE NOW
            </button>
          </a>
        </div>
        <div
          className="hidden w-full bg-center bg-cover md:block md:w-1/2"
          style={{ backgroundImage: `url(${metrodeckerLightest})` }}
          data-aos="fade-left"
          data-aos-duration="1000"
        ></div>
      </section>
      <section className="mt-2 video-container">
        <video
          width="100%"
          preload="metadata"
          loop
          autoPlay
          playsInline
          muted
          className="d-block"
        >
          <source
            src="https://d2dg8z6chpdy03.cloudfront.net/FIRST_YORK%20_B-ROLL.mp4"
            type="video/mp4"
          />
          {/* <source src={video} type='video/mp4' /> */}
          Your browser does not support the video tag.
        </video>
      </section>
      <section className=" bg-[#00002d] text-white  ">
        <div className="pr-3 mx-auto col-lg-10 col-md-12 offset-lg-1 ">
          <div className="p-3 pt-10 text-4xl font-medium text-center md:pb-4 md:pt-16">
            Technical Specifications
          </div>
          <div className="px-4 py-3 mb-5 text-justify sm:px-14 md:px-16 md:pb-10 lg:px-28">
            The lightest double-decker in its class. Our largest zero emission
            bus is the battery electric SWITCH metrodecker. Like the other
            vehicles in the SWITCH family, the SWITCH metrodecker has a fully
            integral frame. This low floor vehicle seats up to 84 and in
            addition has exceptional standing capacity, up to 99 passengers
            depending on the layout. The integral stainless-steel structure and
            the latest driveline technologies ensure the SWITCH metrodecker
            delivers optimum operating efficiencies.
          </div>
          <div className="col-sm-12 " style={{ position: "relative" }}>
            <div className="p-3 md:pb-3 lg:px-28">
              <table className="table m-auto ">
                <tr>
                  <td className="p-1 bg-indigo-500 border-b-2 md:py-2 md:pl-2 md:pr-60 xl:pr-96">
                    Body Length Options
                  </td>
                  <td className="py-1 bg-indigo-500 border-b-2 md:pr-24 lg:pr-48">
                    10.5 m
                  </td>
                  <td className="py-1 bg-indigo-500 border-b-2 md:pr-24 lg:pr-48">
                    11.1 m
                  </td>
                </tr>
                <tr>
                  <td className="p-1 border-b-2 md:p-2">Overall length (mm)</td>
                  <td className="p-1 border-b-2 md:p-2">10,500</td>
                  <td className="p-1 border-b-2 md:p-2">11,140</td>
                </tr>
                <tr>
                  <td className="p-1 border-b-2 md:p-2">Overall width (mm)</td>
                  <td className="p-1 border-b-2 md:p-2">2,500</td>
                  <td className="p-1 border-b-2 md:p-2">2,500</td>
                </tr>
                <tr>
                  <td className="p-1 border-b-2 md:p-2">Overall height (mm)</td>
                  <td className="p-1 border-b-2 md:p-2">4,310</td>
                  <td className="p-1 border-b-2 md:p-2">4,310</td>
                </tr>
                <tr>
                  <td className="p-1 border-b-2 md:p-2">Wheelbase (mm)</td>
                  <td className="p-1 border-b-2 md:p-2">5,350</td>
                  <td className="p-1 border-b-2 md:p-2">6,000</td>
                </tr>
                <tr>
                  <td className="p-1 border-b-2 md:p-2">Front overhang (mm)</td>
                  <td className="p-1 border-b-2 md:p-2">2,760</td>
                  <td className="p-1 border-b-2 md:p-2">2,760</td>
                </tr>
                <tr>
                  <td className="p-1 border-b-2 md:p-2">Rear overhang (mm)</td>
                  <td className="p-1 border-b-2 md:p-2">2,386</td>
                  <td className="p-1 border-b-2 md:p-2">2,386</td>
                </tr>
                <tr>
                  <td className="p-1 border-b-2 md:p-2">
                    Gross vehicle weight design/plated (kg)
                  </td>
                  <td className="p-1 border-b-2 md:p-2">18,000</td>
                  <td className="p-1 border-b-2 md:p-2">18,000</td>
                </tr>
                <tr>
                  <td className="p-1 border-b-2 md:p-2">
                    Front axle weight design/plated (kg)
                  </td>
                  <td className="p-1 border-b-2 md:p-2">6,300</td>
                  <td className="p-1 border-b-2 md:p-2">6,300</td>
                </tr>
                <tr>
                  <td className="p-1 border-b-2 md:p-2">
                    Rear axle weight design/plated (kg)
                  </td>
                  <td className="p-1 border-b-2 md:p-2">11,600</td>
                  <td className="p-1 border-b-2 md:p-2">11,600</td>
                </tr>

                <br />

                <tr>
                  <td className="p-1 bg-indigo-500 border-b-2 md:p-2">
                    Seating Capacity
                  </td>
                  <td className="py-1 bg-indigo-500 border-b-2 md:p-2">
                    10.5 m
                  </td>
                  <td className="py-1 bg-indigo-500 border-b-2 md:p-2">
                    11.1 m
                  </td>
                </tr>
                <tr>
                  <td className="p-1 border-b-2 md:p-2">Seats (maximum)</td>
                  <td className="p-1 border-b-2 md:p-2">63</td>
                  <td className="p-1 border-b-2 md:p-2">84</td>
                </tr>
                <tr>
                  <td className="p-1 border-b-2 md:p-2">Total (maximum)</td>
                  <td className="p-1 border-b-2 md:p-2">94+</td>
                  <td className="p-1 border-b-2 md:p-2">94+</td>
                </tr>
              </table>
            </div>
          </div>
          <div className="pt-4 pb-8 text-center">
            <button className=" my-2 w-min whitespace-nowrap  bg-[#20c997] px-4 py-3  text-white hover:bg-[#515ef5] ">
              Download Brochure
            </button>
          </div>
        </div>
      </section>
      {/* <SideButton setShowMyModal={setShowMyModal} /> */}
    </>
  );
};

export default Metrodecker;
