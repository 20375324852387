import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import patternGreen from "../assets/other/pattern-green.png";

const ThankYou = () => {
  return (
    <div>
      <Helmet>
        <title>Thank You for Choosing Saboo eZone Electric Buses</title>
        <meta
          name="title"
          content="Thank You for Choosing Kasturba Nature Cure Hospital."
        />
        <meta
          name="description"
          content="Thank you for getting in touch with Saboo eZone Electric Vehicles Bus. We appreciate your interest and are committed to providing you with the best in sustainable and reliable transportation solutions. Our team will get back to you shortly. Stay tuned for innovative electric bus options and exceptional service."
        />
        <meta
          name="keywords"
          content="Saboo eZone, Electric Buses, Sustainable Transportation, Eco-Friendly Buses, Efficient Travel, Electric Vehicles, Green Transportation, Thank You, Customer Inquiry, Advanced Electric Buses, Future of Travel, Transportation Solutions, current-vehicles, "
        />
      </Helmet>
      <main
        className="grid min-h-full px-6 py-24 place-items-center sm:py-32 lg:pb-44 lg:px-8"
        style={{ backgroundImage: `url(${patternGreen})` }}
      >
        <div className="text-center">
          <div className="flex justify-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="text-[#02c6b7] w-28 h-28"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          {/* <p className="text-5xl font-semibold text-[#3f8636] ">404</p> */}
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 bg-white sm:text-5xl">
            Thank You
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600 bg-white">
            Thank you for your interest. We will get back to you soon.
          </p>
          <div className="flex items-center justify-center mt-10 gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-[#02c6b7] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-200  flex items-center"
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7 16l-4-4m0 0l4-4m-4 4h18"
                />
              </svg>
              Go back home
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ThankYou;
