import React from "react";
import { Link } from "react-router-dom";
import logoWhite from "../../assets/logo/white logo.png";
import patternPng from "../../assets/other/pattern.png";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="bg-[#505bfe]  bg-opacity-95  px-10 pt-10 text-white">
      <div
        className="grid justify-center w-full gap-4 py-4 pb-10 text-center sm:grid-cols-2 md:grid-cols-4 md:gap-2 md:text-left"
        style={{ backgroundImage: `url(${patternPng})` }}
      >
        <div className="flex flex-col items-center md:items-start">
          <img
            src={logoWhite}
            alt=""
            srcSet=""
            className="h-16 -mt-6 md:h-20"
          />
          {/* <p className="text-lg">A connected world </p>
          <p>Intelligent systems, net zero by design.</p> */}
          <div className="flex gap-4 pt-4 pl-2 text-xl">
            <a
              className=" hover:text-black"
              href="/"
              rel="noopener"
              target="_blank"
            >
              <FaXTwitter />
            </a>
            <a
              className=" hover:text-pink-600"
              href="/"
              rel="noopener"
              target="_blank"
            >
              <FaInstagram />
            </a>

            <a
              className=" hover:text-blue-800"
              href="/"
              rel="noopener"
              target="_blank"
            >
              <FaFacebook />
            </a>
            <a
              className=" hover:text-red-800"
              href="https://www.youtube.com/channel/UCNYKL1eNu04KF-kMU423AuQ"
              rel="noreferrer"
              target="_blank"
            >
              <FaYoutube className="text-2xl" />
            </a>
          </div>
        </div>
        <div className="mx-auto">
          <div className="font-semibold underline uppercase underline-offset-2">
            Helpful Links
          </div><div className="pt-1 hover:text-yellow-300">
            <Link to="/iev-series">Switch eLCV</Link>
          </div>
          <div className="pt-1 hover:text-yellow-300">
            <Link to="/e1">Switch e1</Link>
          </div>
          <div className="pt-1 hover:text-yellow-300">
            <Link to="/metrodecker">Switch Metrodecker</Link>
          </div>
          <div className="pt-1 hover:text-yellow-300">
            <Link to="/metrocity">Switch Metrocity</Link>
          </div>
          <div className="pt-1 hover:text-yellow-300">
            <Link to="/solo">Switch Solo</Link>
          </div>
          
        </div>
        <div className="mx-auto">
          <div className="font-semibold underline uppercase underline-offset-2">
            Others Links
          </div>
          <div className="pt-1 hover:text-yellow-300">
            <Link to="/about">About Us</Link>
          </div>
          <div className="pt-1 hover:text-yellow-300">
            <Link to="/tech">Tech</Link>
          </div>
          <div className="pt-1 hover:text-yellow-300">
            <Link to="/contact">Contact Us</Link>
          </div>
          <div className="pt-1 hover:text-yellow-300">
            <Link to="/privacy-policy">Privacy policy</Link>
          </div>
          <div className="pt-1 hover:text-yellow-300">
            <Link to="/privacy-policy">Terms and conditions</Link>
          </div>
          {/* <div className="pt-1 hover:text-yellow-300">
            <Link to="/careers">Careers</Link>
          </div> */}
        </div>
        {/* <div className="mx-auto">
          <div className="font-semibold underline uppercase underline-offset-2">
            Others websites
          </div>
          <div className="pt-1 hover:text-yellow-300">
            <a href="https://saboomaruti.in/" target="_blank" rel="noreferrer">
              SABOO ARENA
            </a>
          </div>
          <div className="pt-1 hover:text-yellow-300">
            <a
              href="https://www.saboonexa.in/"
              target="_blank"
              rel="noreferrer"
            >
              SABOO NEXA
            </a>
          </div>
          <div className="pt-1 hover:text-yellow-300">
            <a
              href="https://www.sabootruevalue.in/"
              target="_blank"
              rel="noreferrer"
            >
              SABOO TRUE VALUE
            </a>
          </div>
          <div className="pt-1 uppercase hover:text-yellow-300">
            <Link to="/login">Login</Link>
          </div>
        </div> */}
        <div className="mx-auto">
          <div>
            <p className="pb-1 font-semibold underline uppercase underline-offset-2">
              Address
            </p>
            <p className="pb-2">
              Hno: 2- 150, SYR Garden, Warangal High Way,
              <br />
              Jodimetla X Road, Ghatkesar.
              <br />
              Hyderabad, Telangana 501301
            </p>
          </div>

          <div>
            <a
              href="mailto:sabooezone@gmail.com"
              className="hover:text-yellow-300"
            >
              sabooezone@gmail.com
            </a>
            <br />
            Tel:{" "}
            <a href="tel:+919848856789" className="hover:text-yellow-300">
              +91 98488 56789
            </a>
            <br />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-between py-3 mt-2 text-sm border-t md:flex-row">
        <p>© 2024 All Rights Reserved by Saboo Autozone.</p>
        <p>
          Powered by{" "}
          <a
            class="italic hover:text-[#20c997]"
            href="https://broaddcast.com"
            target="_blank"
            rel="noreferrer"
          >
            BroaddCast Business Solutions LLP
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
