import React from 'react'
import { Link } from 'react-router-dom'
import patternGreen from "../assets/other/pattern-green.png";
import sadEmoji from "../assets/other/sad-emoji-svgrepo-com.svg";

const PageNotFound = () => {
  return (
    <div>
      <main
        className="grid min-h-full px-6 py-24 place-items-center sm:py-32 lg:pb-44 lg:px-8"
        style={{ backgroundImage: `url(${patternGreen})` }}
      >
        <div className="text-center">
          <div className="flex justify-center">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              class="text-[#02c6b7] w-28 h-28"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="1"
            >
              <path d="M16 16A8 8 0 1 1 8 16zM13.002 12.002L15 10L16.998 12.002C17.604 12.608 17.604 13.392 16.998 14L15 15L13.002 13C12.396 12.608 12.396 13.392 13.002 14z" />
            </svg> */}
            <img src={sadEmoji} alt="sad emoji" className="bg-white w-28 h-28" />
          </div>
          {/* <p className="text-5xl font-semibold text-[#3f8636] ">404</p> */}
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 bg-white sm:text-5xl">
            Page Not Found
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600 bg-white">
            The page you are looking for does not exist.
          </p>
          <div className="flex items-center justify-center mt-10 gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-[#02c6b7] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-200  flex items-center"
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7 16l-4-4m0 0l4-4m-4 4h18"
                />
              </svg>
              Go back home
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
}

export default PageNotFound

