import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import Select from "@mui/material/Select";

import { IoMdDownload } from "react-icons/io";
import toast from "react-hot-toast";
import { ImSpinner2 } from "react-icons/im";
import axios from "axios";

const EnquireDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [col, setCol] = useState([]);
  const [dateRange, setDateRange] = useState("allData");
  const history = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        if (!token) {
          toast.error("Token expired, Please login again");
          history("/login");
        }
        else {
          const res = await axios.get(
            // "http://localhost:3001/filterData",
            "https://saboo-ezone-backend.onrender.com/filterData",
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCol([
            { field: "id", headerName: "ID" },
            { field: "name", headerName: "Name", flex: 1 },
            { field: "phone", headerName: "Phone Number", flex: 1 },
            { field: "email", headerName: "Email", flex: 1 },
            {
              field: "model",
              headerName: "Model",
              flex: 1,
              renderCell: (params) => {
                console.log(params.row.model);
                return (
                  <div className="flex items-center">
                    <span
                      className={`${
                        params.row.model === "Switch eLCV" && "text-[#505bfe]"
                      }`}
                    >
                      {params.row.model}
                    </span>
                  </div>
                );
              },
            },

            { field: "date", headerName: "Date" },
            { field: "time", headerName: "Time" },
          ]);
          setData(res.data.data);
          setLoading(false);
        }
      } catch (err) {
        setError(err);
        console.log(err);
        toast.error("Token expired, Please login again");
        history("/login");
        setLoading(false);
      }
    };
    fetchData();
  }, [history]);

  let newData = data.map((item, index) => {
    return { ...item, id: index + 1 };
  });

  const handleDownloadCSV = () => {
    const csvData = [];
    const headers = col.map((column) => column.headerName);
    csvData.push(headers);

    newData.forEach((item) => {
      const row = col.map((column) => item[column.field]);
      csvData.push(row);
    });

    const csvContent = csvData.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "Saboo_eZone.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleChangeDateRange = async (event) => {
    const selectedValue = event.target.value;

    setLoading(true);
    setDateRange(selectedValue);
    const token = localStorage.getItem("token");

    let res = await axios.get(
      // "http://localhost:3001/filterData",
      `https://saboo-ezone-backend.onrender.com/filterData`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          date: selectedValue,
        },
      }
    );

    setCol([
      { field: "id", headerName: "ID" },
      { field: "name", headerName: "Name", flex: 1 },
      { field: "phone", headerName: "Phone Number", flex: 1 },
      { field: "email", headerName: "Email", flex: 1 },
      {
        field: "model",
        headerName: "Model",
        flex: 1,
        renderCell: (params) => {
          console.log(params.row.model);
          return (
            <div className="flex items-center">
              <span
                className={`${
                  params.row.model === "Switch eLCV" && "text-[#505bfe]"
                }`}
              >
                {params.row.model}
              </span>
            </div>
          );
        },
      },
      { field: "date", headerName: "Date" },
      { field: "time", headerName: "Time" },
    ]);
    setData(res.data.data);
    setLoading(false);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className="flex justify-between">
        <div>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
        </div>
        <div className="">
          <IconButton
            color="primary"
            onClick={handleDownloadCSV}
            sx={{
              marginRight: "6px",
              backgroundColor: "white",
              fontSize: "14px",
              minWidth: "auto",
              height: "25px",
              color: "#000000",
              "&:hover": {
                color: "#dc2625",
              },
            }}
          >
            <IoMdDownload className="mr-2" /> Download
          </IconButton>
          <Select
            native
            value={dateRange} // Set the value to the state variable
            onChange={handleChangeDateRange}
            className="h-10 p-1  rounded-full bg-[#02c6b7] text-white border-none foucs:outline-none"
          >
            {/* <option value="">All Enquiries</option> */}
            <option value="allData">All Data</option>
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="thisMonth">This Month</option>
            <option value="lastMonth">Last Month</option>
            <option value="last3Months">Last 3 Months</option>
            <option value="last6Months">Last 6 Months</option>
            <option value="last12Months">Last 12 Months</option>
          </Select>
        </div>
      </GridToolbarContainer>
    );
  };

  return (
    <Box m="8px 0 0 0">
      <Box
        // m="40px 0 0 0"
        height="85vh"
        border={1}
        borderColor="grey.300"
        borderRadius="10px"
        sx={{
          "& .MuiDataGrid-root": {
            border: "1",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text ": {
            color: `black`,
          },
        }}
      >
        {loading ? (
          <div className="flex items-center justify-center h-full gap-4 text-xl ">
            <ImSpinner2 className="text-2xl animate-spin " />
            Wait fetching the data from backend.{" "}
          </div>
        ) : error ? (
          "Error ~ Something went wrong :)"
        ) : (
          // ) : data.length < 1 ? (
          //   <div>No data found</div>
          <DataGrid
            rows={newData}
            columns={col}
            slots={{ toolbar: CustomToolbar }}
            sx={{
              backgroundColor: "white",
              fontSize: 15,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default EnquireDashboard;

/* {isLoading ? (
        <p>Loading...</p>
      ) : data.length === 0 ? (
        <p>No data available</p>
      ) : ( 
        <table className=''>
          <thead>
            <tr className='w-full bg-[#02c6b7]  text-white '>
              <th className='p-2 border-r whitespace-nowrap'>Sr. No</th>
              <th className='w-1/5 border-r'>Name</th>
              <th className='w-1/5 border-r'>Model</th>
              <th className='w-1/5 border-r'>Phone</th>
              <th className='w-1/5 border-b '>Email</th>
              <th className='w-1/5 border-b '>Time</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr
                key={index}
                className='text-center hover:bg-[#02c6b7] hover:bg-opacity-20'
              >
                <td className='p-1 border '>{index + 1}</td>
                <td className='p-1 border'>{item.name}</td>
                <td className='p-1 border'>{item.model}</td>
                <td className='p-1 border'>{item.phone}</td>
                <td className='p-1 border'>{item.email}</td>
                <td className='p-1 border'>{item.createTime}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )} */
