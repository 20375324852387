import React, { useEffect } from "react";
import videoPlay from "../assets/video/company-animation-v2.mp4";
import backImage from "../assets/other/banner-company.jpg";

import patternGreen from "../assets/other/pattern-green.png";

import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
// import AboutSlider from './AboutSlider';

const About = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Discover Saboo eZone Bus: Your Trusted Partner in Safe and Reliable
          Transportation Solutions.
        </title>
        <meta
          name="title"
          content="Discover Saboo eZone Bus: Your Trusted Partner in Safe and Reliable Transportation Solutions."
        />
        <meta
          name="description"
          content="Welcome to Saboo eZone Bus, your trusted partner for safe, reliable, and comfortable transportation solutions. With a commitment to excellence and decades of experience in the automotive industry, we specialize in providing high-quality buses for various needs, including public transport, school buses, corporate shuttles, and more. Our fleet boasts modern amenities, advanced safety features, and efficient performance to ensure passenger comfort and peace of mind. Explore our range of buses, learn about our values, and discover why Saboo eZone Bus is the preferred choice for transportation needs."
        />
        <meta
          name="keywords"
          content="Saboo eZone Bus, Transportation Solutions, Bus Services, Passenger Transport, School Buses, Public Transport, Corporate Shuttles, Reliable Transportation, Comfortable Buses, Advanced Safety Features, Modern Amenities, Passenger Comfort, Automotive Excellence, "
        />
      </Helmet>
      <section
        className="grid min-h-[70vh] place-items-center bg-emerald-500 text-white  lg:min-h-[84vh] "
        style={{ backgroundImage: `url(${patternGreen})` }}
      >
        <div className="" data-aos="fade-up">
          <div
            className="flex justify-center text-3xl text-center md:text-4xl lg:text-6xl "
            data-editor-processed="true"
          >
            Hello, we're SABOO eZONE
          </div>
          <div
            className="pt-4 font-mono text-lg text-center md:text-xl lg:text-3xl"
            data-editor-processed="true"
          >
            A next-generation electric vehicle company backed by over 50 years
            of experience.
          </div>
        </div>
      </section>
      <section className="my-2 bg-color-blue company-animation-wrapper">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="col-md-6 company-animation">
            <div className="embed-responsive embed-responsive-16by9">
              <video
                autoPlay
                loop
                muted
                playsInline
                width="100%"
                preload="metadata"
                className="d-block"
              >
                <source src={videoPlay} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div
            className="min-h-[40vh] bg-cover bg-no-repeat md:h-auto lg:h-auto"
            style={{ backgroundImage: `url(${backImage})` }}
          ></div>
        </div>
      </section>
    </>
  );
};

export default About;
